import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 4rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: 'Montserrat-Bold';
  margin: 0 auto 0.75em;
  font-size: 30px;
`

const OurHistoryText = styled.p`
  font-family: 'Montserrat';
  font-size: 15px;
  max-width: 950px;
  margin: 1em auto;
  line-height: 1.5em;
  flex: auto;
`

export const CommercialSec: React.FunctionComponent = () => {
  return (
    <FlexDiv style={{ flexDirection: 'column'}}>
    <OurHistoryWrapper className="ourHistoryWrapper ellicottAdvPage commercialWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv>
          <OurHistoryTitle>Boutique brokerage service. Big brokerage advantages.</OurHistoryTitle>
        </FlexDiv>
        <FlexDiv className="mobileColumn" style={{ flexFlow: 'column' }}>
          <OurHistoryText style={{ flex: 1, textAlign: 'center', maxWidth: '900px' }}>
            Ellicott Realty is a real estate brokerage, servicing clients all across Canada. The Commercial Division combines insightful market knowledge with industry expertise, to execute a range of real estate transactions for landlords, tenants and investors. Our team of professionals are dedicated to achieving your objectives, whether big or small.
          </OurHistoryText>
        </FlexDiv>
      </FlexDiv>
      </OurHistoryWrapper>
      </FlexDiv>
  )
}
