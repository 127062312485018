import React from 'react'
import { FlexDiv } from 'design-system/FlexDiv'
import styled from 'styled-components'
import { GeocoderLocation } from './GeocodeInput'
import activeProspecting from '../images/TeamBoardRoom.jpg'

const IconDiv = styled.button`
  height: 100%;
  width: 10%;
  font-family: CentraNo2-Book;
  display: flex;

  background: white;
  border: 1px solid gray;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const StartSearchTitle = styled.h1`
  font-family: 'CentraNo2-Bold';
  font-size: 30px !important;
  letter-spacing: 1px;
`

const SearchForm = styled.form`
  width: 90%;
  display: flex;
`

export const BoutiqueDesign: React.FC<{
  onSearch: (loc: GeocoderLocation) => void
}> = props => {
  const [searchLocation, setSearchLocation] = React.useState<GeocoderLocation>()

  const handleSearchFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchLocation && props.onSearch) {
      props.onSearch({
        position: searchLocation.position,
        bounds: searchLocation.bounds,
        label: searchLocation.label,
      })
    }
  }
  return (
    <FlexDiv style={{ paddingTop: '3em', paddingBottom: '35px'}} className="marketYourHomeWrapper boutiqueSecWrapper">
    <FlexDiv className="lookToBuySec">
      <FlexDiv className="lookingToBuy advantage">
        
          <div>
            <h2 style={{ marginBottom: '1.5em', fontSize: '30px', fontFamily: 'Montserrat-Bold' }}> Boutique by design</h2>
            </div>
      <FlexDiv 
          style={{
            flex: 2,
            }}
            className="mobileFlexColumn"
        >
        <FlexDiv  className="mobileFlexColumn">
          <FlexDiv className="searchWrapper searchMobileImgWrapper2 boutiqueWrapper" style={{
        background: '#000',
                color: 'white',
          padding: '2em 0 3em'
      }}>
        <p style={{ fontSize: '15px', maxWidth: '62.5%', marginTop: '0px !important' }}>
          We are boutique by design, so not only do you get the advantage of being serviced by senior staff, but it’s a critical way to avoid conflicts of interest and competition priorities. At Ellicott, you’re always working with specialists that understand your unique needs and put you first. Our company reputation is built on providing white-glove service with a personal touch. This is our ongoing promise, and your assurance of success.
        </p>
        </FlexDiv>
        <FlexDiv style={{ flex: 1.3, width: '50%', background: '#000', color: '#fff'}} className="searchMobileImgWrapper">
     
        <img src={activeProspecting} className="searchFor marketingYourHomeImg mobileOnly" />
        <img src={activeProspecting} className="searchFor marketingYourHomeImg desktopOnly"/>
        </FlexDiv>
        
        </FlexDiv>
        </FlexDiv>
        </FlexDiv>
 </FlexDiv>
</FlexDiv>
  )
}
