import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Link } from 'gatsby'
import { Icon } from '../design-system/Icon'
import { Button } from '../design-system/Button'
import Tuxedo from '../images/signup/tux.svg'
import Binocoular from '../images/signup/binocular.svg'
import Pin from '../images/signup/pin.svg'
import MLS from '../images/signup/mls-logo.svg'
import SearchIcon from '../images/signup/search-icon.svg'
import CalendarIcon from '../images/signup/CalendarIcon.svg'
import TrendsIcon from '../images/signup/trends.svg'
import KeyIcon from '../images/signup/key.svg'
import FlashIcon from '../images/signup/flash.svg'
import prefClients from '../images/prefClients.jpg'
import Searchfor from '../images/search-for.png'
import Modal from 'react-modal'
import { LoginComponent, LoginComponentProps } from 'components/LoginComponent'
import { SignUp, SignupComponentProps } from 'components/SignUp'
import propDetails from '../images/global-reach.jpg'
import globalReachMobile from '../images/mobile-global-reach.png'
import { AuthContext } from './EllicotLayout'
import { services } from '@services'
import { slide as Menu } from 'react-burger-menu'

const Links = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.3px;
  color: #ffffff;
  @media (max-width: 1515px) {
    font-size: 15px;
  }
  @media (max-width: 1360px) {
    font-size: 14px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1100px) {
    font-size: 11.5px;
  }
`

const PreffedSignup = styled.button`
  color: #fff;
  border: 1.5px solid #fff;
  padding: 15px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-size: 0.7em;
  width: 100%;
  background: transparent;
  margin-top: 2em;
  font-weight: 600;
`

const StyledCard = styled.div`
    height:300px
    background: gray
`

const DivWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2em 0 5em;
`

const CardTitle = styled.h3`
  font-family: 'CentraNo2-Bold';
  -webkit-font-smoothing: antialiased;
  margin: 1em 2em;
`

const CardTitle2 = styled.h3`
  font-family: 'CentraNo2-Bold';
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 2em 0 0.5em;
`

const CardSubTitle = styled.p`
  font-family: 'CentraNo2-Light';
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
`

const IconRightHack = styled.div`
  align-self: flex-end;
  margin: 1em;
`

const SubText = styled.p`
  font-family: 'CentraNo2-Book';
  -webkit-font-smoothing: antialiased;
  font-size: 0.9em;
`

const InputFieldTemp = styled.div`
  font-family: 'CentraNo2-Light';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #fff;
  width: 100%;
  padding: 10px;
`

const SignUpBtn = styled.div`
  text-align: center;
  font-family: CentraNo2-Book;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000;
  padding: 10px;
  font-size: 0.9em;
`

const FeaturedTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
`

const PreferredSubText = styled.p`
  font-family: CentraNo2-Book;
  font-weight: 100;
  font-size: 0.8em;
`

const IconImg = styled.img`
  height: 2em;
`

export const WhyWork: React.FunctionComponent<HeaderPropsType> = props => {
  const [loginIsOpen, setLoginIsOpen] = React.useState(false)
  const [signupIsOpen, setSignupIsOpen] = React.useState(false)

  const openLogin = () => {
    setLoginIsOpen(true)
  }

  const closeLogin = () => {
    setLoginIsOpen(false)
  }

  const openSignup = () => {
    setSignupIsOpen(true)
  }

  const closeSignup = () => {
    setSignupIsOpen(false)
  }

  const loginUser: LoginComponentProps['handleLoginUser'] = ({
    credentials,
    rememberMe,
  }) => {
    services.firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(closeLogin)
  }
  const signupUser: SignupComponentProps['handleSignupUser'] = formData => {
    services.firebase
      .auth()
      .createUserWithEmailAndPassword(formData.email, formData.password)
    services.lifted.sendRegisterForm(formData)
  }

  return (
    <FlexDiv style={{ paddingTop: '6em', boxShadow: 'rgb(0 0 0 / 10%) 0px 13px 15px 2px' }} className="globalReachWrapper commercialServicesWrapper">
      <FlexDiv className="propDetailsFlex ServiceSec" style={{ maxHeight: '425px', flex: '1.1' }}>
        <div style={{ background: '#fff'}}>
          <h1>
            Services include:
          </h1>
          <ul>
            <li>Research and Development</li>
            <li>Feasibility Studies</li>
            <li>Investment Services</li>
            <li>Landlord Representation</li>
            <li>Tenant Representation</li>
            <li>Off Market Opportunities </li>
            <li>Lease Evaluation Services</li>
            <li>National Portfolio Management</li>
            <li>Highest and best use Studies</li>
            <li>Land Development </li>
            <li>Database Management</li>
          </ul>
        </div>
      </FlexDiv>
      <FlexDiv style={{ background: '#000', placeContent: 'flex-start' }} className="propDetailsTextWrapper whyWorkWithUs">
        <h2 className="propDetailsTitle"> Why work with us?</h2>
        <FlexDiv className="propDetailsText">
        <p style={{ color: '#fff'}}>
          Our commercial real estate agents bring the breadth and depth needed to service any type of real estate transaction. Our agents have years of experience satisfying the needs of our clients in the GTA and across Canada.
        </p>
        <p style={{ color: '#fff'}}>
            Our size allows us to deliver a white glove experience that is both trustworthy and genuine. We constantly work to exceed and redefine even the highest expectations.
        </p>
          </FlexDiv>
      </FlexDiv>
  </FlexDiv>
  )
}
