import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { CommercialSec } from 'components/CommercialSec'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { CommercialHero } from 'components/CommercialHero'
import { BoutiqueDesign } from 'components/BoutiqueDesign'
import { Agents } from 'components/Agents'
import { WhyWork } from 'components/WhyWork'
import { KingCityCommunityInformation } from 'components/KingCityCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchKingCity'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import { AffiliateNetwork } from 'components/AffiliateNetwork'

const AuroraPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <CommercialHero />
      <CommercialSec />
      <BoutiqueDesign />
      <Agents />
      <WhyWork />
    </EllicotLayout>
  )
}

export default AuroraPage
